

function Calendar() {
    return (
        <>
            <iframe
                src="https://calendar.google.com/calendar/embed?src=bebb116280a14d357c4fa473ea7a45ac7acefeff2ec9712beb8581b31eb9adf3%40group.calendar.google.com&ctz=America%2FChicago"
                style={{ border: 0 }}
                width="800"
                height="600"
                frameborder="0"
                scrolling="no">
            </iframe>
        </>
    )
}

export default Calendar